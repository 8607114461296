function getURLParameter(name) {
  const param = new URLSearchParams(location.search).get(name);
  if (param) return param.replace(/^"(.*)"$/, "$1");
  return null;
}

function setTeamUrl() {

  let team = getURLParameter("team") || "";
  team = team.replace(/\/$/g, ""); // no trailing slash
  if (!/^((?:http(s)?)\:)?\/\/.+/.test(team)) team = team.replace(/^(.*\/\/)?(.+)/g, "https://$2"); // use https if no protocol is provided
  if (team) localStorage.setItem("team", team);
  
}

// const openIdPath = "fw-openidconnectconsumerserver-dynamic";
const openIdBaseUrl = process.env.OCS_URL;

function monitorVisibilityChange() {
  // Set the name of the hidden property and the change event for visibility
  let hidden, visibilityChange;
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    hidden = "hidden";
    visibilityChange = "visibilitychange";
    state = "visibilityState";
  } else if (typeof document.mozHidden !== "undefined") {
    hidden = "mozHidden";
    visibilityChange = "mozvisibilitychange";
    state = "mozVisibilityState";
  } else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
    state = "msVisibilityState";
  } else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
    state = "webkitVisibilityState";
  }

  document.addEventListener(visibilityChange, () => {
    if (!document[hidden]) attemptTokenRefresh();
  });
}

function getLoginRedirectUrl() {
  const loginUrl = new URL(location);
  loginUrl.pathname = "/login";

  const team = getURLParameter("team");
  const params = new URLSearchParams(location.search);

  if (team) {
    params.delete("team");
    loginUrl.searchParams.set("team", team);
  }

  const destination = `${location.pathname}?${params.toString()}`.replace(/\?$/, "");

  loginUrl.searchParams.set("next", destination);

  return loginUrl.toString();

}

//Verify token
async function attemptTokenRefresh(showLoading) {
  if (showLoading) showPageLoading();

  const team = localStorage.getItem("team");
  const realm = localStorage.getItem("realm");
  const token = sessionStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");

  if (!refreshToken || !team) return (window.location = getLoginRedirectUrl());

  // const realmOpenIdPath = `${openIdPath}/${realm ?? "fundwave"}`;
  const baseAddress = `${openIdBaseUrl}/${realm ?? "fundwave"}/`;
  const tokenRefreshURL = `${baseAddress}token/refresh`;

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (token) headers["Authorization"] = `Bearer ${token}`;
  if (refreshToken) headers["Refresh-Token"] = refreshToken;

  await fetch(tokenRefreshURL, {
    headers,
  })
    .then((res) => {
      hidePageLoading();
      if (!res.ok) return (window.location = getLoginRedirectUrl());
      return res.json();
    })
    .then((data) => {
      const token = data["token"];
      const refreshToken = data["refreshToken"];
      if (token) sessionStorage.setItem("token", token);
      if (refreshToken) localStorage.setItem("refreshToken", refreshToken);

      verifyTokenUser(showLoading);
    })
    .catch((err) => {
      console.log(err);
      return (window.location = getLoginRedirectUrl());
    });
}

async function verifyTokenUser(isLoading) {
  if (isLoading) showPageLoading();

  const token = sessionStorage.getItem("token");
  const team = localStorage.getItem("team");

  //Verify User
  const requestURL = `${team || window.location.origin}/services/api/user`;

  const headers = { Accept: "application/json" };
  if (token) headers["Authorization"] = `Bearer ${token}`;

  fetch(requestURL, { headers })
    .then((response) => {
      const loginUrl = getLoginRedirectUrl();
      if (!response.status || response.status == 404) return (window.location = `${loginUrl}&error=invalidteam`);
      else if (response.status == 401 || response.status == 403) return (window.location = `${loginUrl}&error=noaccess`);
      else if (response.status == 424) return (window.location = `${loginUrl}?error=requiredmfa`);
      else if (response.status != 200) return (window.location = `${loginUrl}&error=servererror`);
    })
    .catch((err) => {
      console.log(err);
      const loginUrl = getLoginRedirectUrl();
      window.location = `${loginUrl}&error=noaccess`;
    });
}

function showPageLoading() {
  // document.getElementById('loader').style.display = 'inline-block';
  // document.getElementById('app').style.display = 'none';
  document.getElementById("app").authenticating = true;
}

function hidePageLoading() {
  // document.getElementById('app').style.display = 'inline-block';
  // document.getElementById('loader').style.display = 'none';
  document.getElementById("app").authenticating = false;
}

(function init() {
  window.process = { env: { NODE_ENV: "production" } };
  window.AppcuesSettings = { enableURLDetection: true };

  setTeamUrl();
  attemptTokenRefresh();
  monitorVisibilityChange();
})();
